import React from "react";

const Telegram = () => {
  return (
    <div className='sm:w-16 w-12 sm:h-4 h-2 cursor-pointer text-white fixed sm:bottom-52 bottom-20 right-10 flex flex-col transform translate-x-2 ease-in-out opacity-80 sm:opacity-100'>
      <a href='https://t.me/nudecennetidestek' target='_blank' rel="noopener noreferrer">
        <h1 className='sm:!text-sm text-xs !mb-1 !text-[#4a90e2]'>Canlı Destek</h1>
        <img
          src='/images/Telegram_logo.svg'
          alt='Telegram Live Support'
        />
      </a>
    </div>
  );
};

export default Telegram;

import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/authContext";
import Layout from "./components/Layout";
import DynamicSEO from './hooks/DynamicSEO';
import useCorpSchema from './Schemas/corpSchema';
import Anasayfa from "./components/Anasayfa";
import Foto from "./components/Foto";
import Video from "./components/Video";
import Premium from "./components/Premium";
import Payment from "./components/Payment";
import Hakkimizda from "./components/Hakkimizda";
import GizlilikPolitikasi from "./components/GizlilikPolitikasi";
import KullanimSartlari from "./components/KullanimSartlari";
import Iletisim from "./components/Iletisim";
import Register from "./components/Register";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import Agewarning from "./components/ageWarning";
import FileUpload from "./components/FileUpload";

function App() {
  // Run organizational schema globally
  useCorpSchema();

  return (
    <AuthProvider>
      <Router>
        <Layout>
          <Agewarning />
          <Routes>
            <Route
              path='/'
              element={
                <>
                  <DynamicSEO
                    title="nudecenneti.com - Home of HD Adult Content"
                    description="Welcome to nudecenneti.com, the best place for high-quality adult content."
                    canonical="https://nudecenneti.com/"
                  />
                  <Anasayfa />
                </>
              }
            />
            <Route
              path='/foto'
              element={
                <>
                  <DynamicSEO
                    title="High-Quality Adult Photos | nudecenneti.com"
                    description="Browse our extensive library of high-quality adult photos."
                    canonical="https://nudecenneti.com/foto"
                  />
                  <Foto />
                </>
              }
            />
            <Route
              path='/video'
              element={
                <>
                  <DynamicSEO
                    title="High-Quality Adult Videos | nudecenneti.com"
                    description="Watch premium adult videos in HD quality at nudecenneti.com."
                    canonical="https://nudecenneti.com/video"
                  />
                  <Video />
                </>
              }
            />
            <Route
              path='/premium'
              element={
                <>
                  <DynamicSEO
                    title="Premium Content | nudecenneti.com"
                    description="Access exclusive premium content by becoming a member."
                    canonical="https://nudecenneti.com/premium"
                  />
                  <Premium />
                </>
              }
            />
            <Route
              path='/payment'
              element={
                <>
                  <DynamicSEO
                    title="Payment Options | nudecenneti.com"
                    description="Choose from our payment options to access premium content."
                    canonical="https://nudecenneti.com/payment"
                  />
                  <Payment />
                </>
              }
            />
            <Route
              path='/hakkimizda'
              element={
                <>
                  <DynamicSEO
                    title="About Us | nudecenneti.com"
                    description="Learn more about nudecenneti.com and our commitment to quality content."
                    canonical="https://nudecenneti.com/hakkimizda"
                  />
                  <Hakkimizda />
                </>
              }
            />
            <Route
              path='/gizlilik-politikasi'
              element={
                <>
                  <DynamicSEO
                    title="Privacy Policy | nudecenneti.com"
                    description="Read our privacy policy to understand how we protect your data."
                    canonical="https://nudecenneti.com/gizlilik-politikasi"
                  />
                  <GizlilikPolitikasi />
                </>
              }
            />
            <Route
              path='/kullanim-sartlari'
              element={
                <>
                  <DynamicSEO
                    title="Terms of Service | nudecenneti.com"
                    description="Read our terms of service before using nudecenneti.com."
                    canonical="https://nudecenneti.com/kullanim-sartlari"
                  />
                  <KullanimSartlari />
                </>
              }
            />
            <Route
              path='/iletisim'
              element={
                <>
                  <DynamicSEO
                    title="Contact Us | nudecenneti.com"
                    description="Get in touch with us through our contact page."
                    canonical="https://nudecenneti.com/iletisim"
                  />
                  <Iletisim />
                </>
              }
            />
            <Route
              path='/register'
              element={
                <>
                  <DynamicSEO
                    title="Register | nudecenneti.com"
                    description="Join nudecenneti.com by creating an account today."
                    canonical="https://nudecenneti.com/register"
                  />
                  <Register />
                </>
              }
            />
            <Route
              path='/login'
              element={
                <>
                  <DynamicSEO
                    title="Login | nudecenneti.com"
                    description="Access your nudecenneti.com account by logging in."
                    canonical="https://nudecenneti.com/login"
                  />
                  <Login />
                </>
              }
            />
            <Route
              path='/reset-password'
              element={
                <>
                  <DynamicSEO
                    title="Reset Password | nudecenneti.com"
                    description="Recover access to your nudecenneti.com account."
                    canonical="https://nudecenneti.com/reset-password"
                  />
                  <ForgotPassword />
                </>
              }
            />
            <Route
              path='/fileupload'
              element={
                <>
                  <DynamicSEO
                    title="File Upload | nudecenneti.com"
                    description="Upload files to nudecenneti.com."
                    canonical="https://nudecenneti.com/fileupload"
                  />
                  <FileUpload />
                </>
              }
            />
          </Routes>
        </Layout>
      </Router>
    </AuthProvider>
  );
}

export default App;

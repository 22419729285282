import React from 'react';
import './KullanimSartlari.css';
import DynamicSEO from '../hooks/DynamicSEO';
import Telegram from './Telegram';


function KullanimSartlari() {
  DynamicSEO({
    title: 'Kullanım Şartları - NudeCenneti',
    description:
      'Sitemizin kullanım kuralları ve koşulları hakkında bilgi alabileceğiniz Kullanım Şartları sayfası. Platformumuzu nasıl kullanmanız gerektiği hakkında detaylı bilgi.',
    keywords: 'Kullanım Şartları, hizmet şartları, site kuralları, kullanıcı koşulları, hizmet kullanım şartları',
  });

  return (
    <div className="kullanim-sartlari">
      <h1>Kullanım Şartları</h1>
      <p>Son Güncelleme: 28 Ekim 2024</p>
      
      <h2>1. Genel Bilgiler</h2>
      <p>
        NudeCenneti ("Şirket", "biz", "bizi") tarafından sağlanan web sitesi ve uygulama (topluca "Hizmetler"), kullanıcı ("siz", "Kullanıcı") olarak size sunulmaktadır. Hizmetlerimizi kullanarak bu kullanım şartlarını ("Şartlar") kabul etmiş sayılırsınız. Kabul etmiyorsanız, Hizmetlerimizi kullanmayınız.
      </p>

      <h2>2. Hesap Oluşturma ve Güvenlik</h2>
      <p>
        Hizmetlere erişim için bir hesap oluşturmanız gerekebilir. Hesap bilgilerinizin doğruluğu ve güvenliği sizin sorumluluğunuzdadır. Hesabınızı korumak için gerekli önlemleri almalısınız; aksi durumda Şirket sorumluluk kabul etmez.
      </p>

      <h2>3. İçerik ve Kullanım Hakları</h2>
      <p>
        Hizmetlerde yer alan içerikler, grafikler ve materyaller Şirket mülkiyetindedir ve telif hakkı yasaları ile korunmaktadır. Bu içerikleri izinsiz kopyalamak, dağıtmak veya değiştirmek yasaktır; içerik yalnızca kişisel kullanım içindir.
      </p>

      <h2>4. Kullanıcı Davranışları</h2>
      <p>
        Hizmetlerimizi kullanırken yasalara aykırı, zararlı veya rahatsız edici davranışta bulunmak yasaktır. Şirket, bu tür davranışları engelleme ve gerekirse kullanıcı hesaplarını askıya alma hakkını saklı tutar.
      </p>

      <h2>5. Üçüncü Taraf Bağlantıları</h2>
      <p>
        Hizmetlerimizde üçüncü taraf web sitelerine yönlendirmeler olabilir. Bu bağlantılar yalnızca referans amaçlıdır ve bu sitelerin içeriklerinden veya politikalarından sorumlu değiliz.
      </p>

      <h2>6. Sorumluluk Sınırlaması</h2>
      <p>
        Hizmetlerimiz "olduğu gibi" sunulmaktadır; herhangi bir garanti verilmemektedir. Kesintisiz veya hatasız hizmet sağlanacağını garanti edemeyiz. Şirket, dolaylı veya tesadüfi zararlardan sorumlu tutulamaz.
      </p>

      <h2>7. Değişiklikler</h2>
      <p>
        Şirket, bu Şartlarda değişiklik yapma hakkını saklı tutar. Güncellemelerden sonra Hizmetleri kullanmanız, değişiklikleri kabul ettiğiniz anlamına gelir.
      </p>

      <h2>8. İletişim</h2>
      <p>
        Şartlarla ilgili sorularınız için <a href="mailto:isteknudecenneti@gmail.com">isteknudecenneti@gmail.com</a> adresinden bizimle iletişime geçebilirsiniz.
      </p>
      <Telegram /> 

    </div>
    
  );
}

export default KullanimSartlari;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Anasayfa.css';
import Modal from './Modal';
import { ImageSchema, VideoSchema } from '../Schemas/VideoSchema';
import DynamicSEO from '../hooks/DynamicSEO';
import useCorpSchema from '../Schemas/corpSchema';
import Telegram from './Telegram';

function Anasayfa() {
  const [content, setContent] = useState({ images: [], videos: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [visibleImages, setVisibleImages] = useState(10);
  const [visibleVideos, setVisibleVideos] = useState(10);

  useCorpSchema();
  DynamicSEO({
    title: 'Nude Cenneti',
    description:
      'Yetişkinlere özel nudecenneti.com sitemizde, yüksek kaliteli cinsel içerikler, videolar ve görseller sunuyoruz. Güvenli, anonim ve hızlı erişim ile aradığınız her şeyi bulabilirsiniz. Tek ihtiyacınız nude cenneti.',
    keywords:
      'porn,porno,porna,sex,sikiş,sikiş porno,nude, nude cenneti,nude foto,çıplak,foto, sex videoları, erotik foto, erotik videolar, erotik video,liseli ifşa,ifşa videoları,sex izle,porno izle,japon porno,zenci porno,lezbiyen sex,türbanlı sex,türbanlı porno,kürt porno,latin porno',
    canonical: `${window.location.href}`,
  });

  useEffect(() => {
    fetchContent();
  }, []);

  const fetchContent = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://benudecenneti.com/api/media/anasayfa'); // Correct URL
      const images = response.data.filter(
        (item) => item && item.contentType && item.contentType.startsWith('image'),
      );
      const videos = response.data.filter(
        (item) => item && item.contentType && item.contentType.startsWith('video'),
      );
      setContent({ images, videos });
    } catch (error) {
      console.error('İçerik yüklenirken hata oluştu:', error);
      setError('İçerik yüklenemedi. Lütfen daha sonra tekrar deneyin!');
    } finally {
      setLoading(false);
    }
  };

  const handleContentClick = (item) => {
    if (item.premium) {
      navigate('/premium');
    } else {
      setModalContent(item);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  if (loading) return <div className='loading'>Yükleniyor...</div>;
  if (error) return <div className='error'>{error}</div>;

  const loadMoreImages = () => {
    setVisibleImages((prevVisible) => prevVisible + 5);
  };

  const loadMoreVideos = () => {
    setVisibleVideos((prevVisible) => prevVisible + 5);
  };

  return (
    <div className='anasayfa'>
      <h1>Hoş Geldiniz</h1>
      <p id='ensoniceriktext'>Ücretsiz içeriklerimize göz atın.</p>

      <section className='content-section'>
        <h2>Fotoğraflar ({content.images.length})</h2>
        <div className='content-slider'>
          {content.images.slice(0, visibleImages).map((item) => (
            <div
              key={item._id}
              className='content-item'
              onClick={() => handleContentClick(item)}>
              <img
                src={`${item.filePath}`}
                alt={item.filename.slice(0, -4)} // Use the filename without the extension for the alt text
                className={item.premium ? 'blurred' : ''}
                loading='lazy'
              />
              {item.premium && <span className='premium-badge'>Premium</span>}
            </div>
          ))}
        </div>
        {visibleImages < content.images.length && (
          <button
            onClick={loadMoreImages}
            className='add-more-button'>
            Daha Fazla
          </button>
        )}

        <ImageSchema images={content.images} />
      </section>

      <section className='content-section'>
        <h2>Videolar ({content.videos.length})</h2>
        <div className='content-slider'>
          {content.videos.slice(0, visibleVideos).map((item) => (
            <div
              key={item._id}
              className='content-item'
              onClick={() => handleContentClick(item)}>
              <video
                src={`${item.filePath}#t=0.001`}
                alt={item.filename.slice(0, -4)} // Use the filename without the extension for the alt text
                className={item.premium ? 'blurred' : ''}
                controls
                preload='metadata'
              />
              {item.premium && <span className='premium-badge'>Premium</span>}
            </div>
          ))}
        </div>
        {visibleVideos < content.videos.length && (
          <button
            onClick={loadMoreVideos}
            className='add-more-button'>
            Daha Fazla
          </button>
        )}
        <Telegram />
        <VideoSchema videos={content.videos} />
      </section>

      <div className='navigation'>
        <Link
          to='/premium'
          className='nav-button'>
          Premium İçerikler
        </Link>
      </div>

      {isModalOpen && (
        <Modal
          content={modalContent}
          onClose={closeModal}
          open={isModalOpen}
        />
      )}
    </div>
  );
}

export default Anasayfa;
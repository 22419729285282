import React from 'react';

const VideoSchema = ({ videos }) => {
  const schemaData = videos.map((video) => {
    // Clean and format the filename for better SEO
    const nameWithoutExt = video.filename
      ?.replace(/\.[^/.]+$/, "")
      ?.split(/[_-]/)
      ?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      ?.join(" ") || "Yetişkin Video İçeriği";

    // Create description with keywords for better SEO
    const description = `${nameWithoutExt} - Nude Cenneti'nde yüksek kaliteli yetişkin içerik. Türkiye'nin en güvenilir yetişkin içerik platformu.`;

    // Use the actual thumbnailUrl from database, fallback to constructed URL if needed
    const thumbnailUrl = video.thumbnailUrl || 
                        `${video.filePath.substring(0, video.filePath.lastIndexOf('/'))}/` +
                        `${video.key.replace(/\.[^/.]+$/, "")}-thumbnail.jpg`;

    return {
      '@context': 'http://schema.org',
      '@type': 'VideoObject',
      name: nameWithoutExt,
      description: description,
      uploadDate: video.uploadDate || new Date().toISOString(),
      contentUrl: video.filePath,
      thumbnailUrl: thumbnailUrl,
      duration: "PT1M", // Consider implementing actual duration detection
      inLanguage: 'tr',
      contentSize: `${Math.round(video.length / (1024 * 1024))}MB`,
      encodingFormat: video.contentType || 'video/mp4',
      height: '720',
      width: '1280',
      accessMode: 'visual',
      isFamilyFriendly: false,
      genre: 'Adult',
      publisher: {
        '@type': 'Organization',
        name: 'nudecenneti.com',
        logo: {
          '@type': 'ImageObject',
          url: 'https://nudecenneti.com/images/nudecenneti.png'
        }
      }
    };
  });

  return <script type="application/ld+json">{JSON.stringify(schemaData)}</script>;
};

const ImageSchema = ({ images }) => {
  const schemaData = images.map((image) => {
    const nameWithoutExt = image.filename
      ?.replace(/\.[^/.]+$/, "")
      ?.split(/[_-]/)
      ?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      ?.join(" ") || "Yetişkin Görsel İçeriği";

    // Use thumbnailUrl for preview if available
    const thumbnailUrl = image.thumbnailUrl || image.filePath;

    return {
      '@context': 'http://schema.org',
      '@type': 'ImageObject',
      name: nameWithoutExt,
      contentUrl: image.filePath,
      thumbnailUrl: thumbnailUrl,
      uploadDate: image.uploadDate || new Date().toISOString(),
      description: `${nameWithoutExt} - Nude Cenneti özel yetişkin içerik koleksiyonu.`,
      inLanguage: 'tr',
      encodingFormat: image.contentType || 'image/jpeg',
      height: '1080',
      width: '1920',
      contentSize: `${Math.round(image.length / (1024 * 1024))}MB`,
      isFamilyFriendly: false,
      genre: 'Adult',
      publisher: {
        '@type': 'Organization',
        name: 'nudecenneti.com',
        logo: {
          '@type': 'ImageObject',
          url: 'https://nudecenneti.com/images/nudecenneti.png'
        }
      }
    };
  });

  return <script type="application/ld+json">{JSON.stringify(schemaData)}</script>;
};

export { VideoSchema, ImageSchema };
import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  forgotPassword,
  updateProfile,
  resetPassword,
} from "../services/authService";
import "./Login.css";

function ForgotPassword() {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenFromUrl = queryParams.get("token");
    if (tokenFromUrl) {
      setFormData((prevState) => ({
        ...prevState,
        token: tokenFromUrl,
      }));
    }
  }, [location]);

  const validate = () => {
    const errors = {};
    if (!formData.email && !formData.token) errors.email = "Email is required";
    if (!formData.password && formData.token)
      errors.password = "Password is required";
    if (formData.password !== formData.confirmPassword && formData.token)
      errors.confirmPassword = "Passwords do not match";
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    console.log("inside submit", validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setIsLoading(true);
    setError("");
    setSuccessMessage("");
    try {
      const success = await forgotPassword(formData.email);
      setSuccessMessage(success.message);
      setTimeout(() => {
        navigate("/");
      }, 1500);
    } catch (err) {
      setError("Bir hata oluştu. Lütfen daha sonra tekrar deneyin!");
      console.error("forgotpassword error:", err);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSubmitPassowrd = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    console.log("inside submit", formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setIsLoading(true);
    setError("");
    setSuccessMessage("");
    try {
      const success = await resetPassword(formData.password, formData.token);
      setSuccessMessage(success.message);
      setTimeout(() => {
        navigate("/");
      }, 1500);
    } catch (err) {
      setError("Bir hata oluştu. Lütfen daha sonra tekrar deneyin!");
      console.error("forgotpassword error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login">
      {formData.token ? (
        <>
          <h1>şifreyi sıfırla</h1>
          <form onSubmit={handleSubmitPassowrd}>
            <div className="form-group">
              <label htmlFor="password">Şifre</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              {errors.password && <p className="error">{errors.password}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Şifreyi Onayla</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
              {errors.confirmPassword && (
                <p className="error">{errors.confirmPassword}</p>
              )}
            </div>
            <div className="login-button-container">
              <button
                type="submit"
                className="login-button"
                disabled={isLoading}
              >
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </>
      ) : (
        <>
          <h1>şifreyi sıfırla</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Eposta</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="login-button-container">
              <button
                type="submit"
                className="login-button"
                disabled={isLoading}
              >
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </>
      )}
      {error && <p className="error-message">{error}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
    </div>
  );
}
export default ForgotPassword;

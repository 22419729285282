import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from '../api/axios';
import './Payment.css';
import Telegram from './Telegram';

function Payment() {
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState(location.state?.amount || 350);
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage('');

    try {
      const response = await axios.post('/auth/initiate-payment', { email, amount });
      console.log('Server response:', response.data);
      setMessage(response.data.message);
    } catch (error) {
      console.error('Error details:', error);
      if (error.response) {
        setMessage(`Error: ${error.response.data.error || 'An unknown error occurred'}`);
      } else if (error.request) {
        setMessage('No response received from the server. Please try again.');
      } else {
        setMessage('An error occurred while sending the request. Please try again.');
      }
    }

    setIsSubmitting(false);
  };

  return (
    <div className="payment">
      <h1>Premium Üyelik İşlemleri</h1>
      
      <div className="process-info">
        <h2>🔥 Önemli Bilgilendirme</h2>
        <p>Premium üyelik işlemi 3 basit adımdan oluşur:</p>
        <ol>
          <li><strong>Form Doldurma:</strong> E-posta adresinizi girin ve üyelik planınızı seçin</li>
          <li><strong>Ödeme:</strong> Size gönderilecek e-postadaki hesap bilgilerine ödemeyi yapın</li>
          <li><strong>Hesap Oluşturma:</strong> Ödemeniz onaylandıktan sonra (15-30 dk) size gönderilecek bağlantı ile hesabınızı oluşturun</li>
        </ol>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">E-posta Adresi:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="örnek@gmail.com"
          />
        </div>

        <div className="form-group">
          <label htmlFor="subscription">Abonelik Planı:</label>
          <select
            id="subscription"
            value={amount}
            onChange={(e) => setAmount(parseInt(e.target.value))}
            required
          >
            <option value={350}>1 Aylık Premium - 350 TRY</option>
            <option value={1750}>6 Aylık Premium - 1750 TRY</option>
            <option value={3500}>12 Aylık Premium - 3500 TRY</option>
          </select>
        </div>

        <div className="payment-methods">
          <div className="papara-section">
            <h3>Papara ile Ödeme</h3>
            <p><strong>Papara Hesap No:</strong> 1982400478</p>
            <div className="warning-box">
              <p><strong>⚠️ ÖNEMLİ:</strong> Ödeme yaparken açıklama kısmını boş bırakınız!</p>
            </div>
          </div>

          <div className="crypto-section">
            <h3>Kripto Para ile Ödeme</h3>
            <div className="crypto-addresses">
              <div className="crypto-item">
                <span>Bitcoin (BTC):</span>
                <code>bc1qqundrmq43whcr95znkycvtqe7xalrdfs539m8q</code>
              </div>
              <div className="crypto-item">
                <span>USDT (ETH):</span>
                <code>0x39CcD8f869422c362648795FCa4a1B89bd47eB1F</code>
              </div>
              <div className="crypto-item">
                <span>Ethereum (ETH):</span>
                <code>0x39CcD8f869422c362648795FCa4a1B89bd47eB1F</code>
              </div>
              <div className="crypto-item">
                <span>XRP:</span>
                <code>rhDH5GU9gQr8BtjLuQQJfaqJvpw2xtFwqQ</code>
              </div>
              <div className="crypto-item">
                <span>BNB (BSC):</span>
                <code>0x39CcD8f869422c362648795FCa4a1B89bd47eB1F</code>
              </div>
              <div className="crypto-item">
                <span>USDC (ETH):</span>
                <code>0x39CcD8f869422c362648795FCa4a1B89bd47eB1F</code>
              </div>
            </div>
          </div>
        </div>

        <button type="submit" className="submit-button" disabled={isSubmitting}>
          {isSubmitting ? 'İşleniyor...' : 'Ödeme Talimatlarını Al ve Başla'}
        </button>
      </form>

      {message && <p className="message">{message}</p>}
      
      <Telegram />

<div className="patreon-section">
  <p className="text-center text-gray-700 mb-4">
    Bu bölüm, kredi kartı ile ödeme yaparak üyelik edinmenizi sağlar. Üyelik, özel içeriklere ve avantajlara erişim imkanı sunar.
  </p>
  <a href="https://www.patreon.com/NudeCenneti" target="_blank" rel="noopener noreferrer">
    <img
      className="w-full h-12 rounded text-base cursor-pointer transition duration-300 ease-in"
      src="https://c5.patreon.com/external/logo/become_a_patron_button.png"
      alt="Support on Patreon"
      style={{ objectFit: 'contain' }} // Ensures the image maintains its aspect ratio
    />
  </a>
</div>


    </div>
  );
}

export default Payment;
import { useEffect } from 'react';

const useCorpSchema = () => {
	useEffect(() => {
		const schema = {
			'@context': 'http://schema.org',
			'@type': 'Organization',
			name: 'nudecenneti.com',
			alternateName: 'HD Porno Filmleri Sikiş İzle Türk Pornoları',
			url: 'https://nudecenneti.com/',
			logo: 'https://nudecenneti.com/images/nudecenneti.png',
			sameAs: [],
		};

		const script = document.createElement('script');
		script.type = 'application/ld+json';
		script.text = JSON.stringify(schema);
		document.head.appendChild(script);

		return () => {
			document.head.removeChild(script);
		};
	}, []);
};

export default useCorpSchema;

import React, { useEffect, useRef } from 'react';
import './Modal.css';

function Modal({ content, onClose, open }) {
	const videoRef = useRef(null); // Create a ref to keep track of the video element
	const isImage = content.contentType.startsWith('image');
	const isVideo = content.contentType.startsWith('video');

	useEffect(() => {
		if (isVideo && open) {
			const video = videoRef.current; // Get the video element reference
			if (video) {
				video.play(); // Play the video when the modal opens
			}
		}

		// Cleanup function to pause video when modal closes
		return () => {
			if (videoRef.current) {
				videoRef.current.pause(); // Pause the video on modal close
				videoRef.current.currentTime = 0; // Optionally reset video time to start
			}
		};
	}, [open, content]); // Added content to dependency array to re-run effect if content changes

	return (
		<div className='modal-backdrop' onClick={onClose}>
			<div className='modal-content' onClick={(e) => e.stopPropagation()}>
				{isImage && (
					<img
						src={`${content.filePath}`}
						alt={content.filename}
					/>
				)}
				{isVideo && (
					<video
						ref={videoRef} // Attach ref to video element
						src={`${content.filePath}`}
						controls
						autoPlay
					/>
				)}
				<button className='close-button' onClick={onClose}>
					Close
				</button>
			</div>
		</div>
	);
}

export default Modal;

import { useEffect } from 'react';

const DynamicSEO = ({ title, description, keywords = [] ,canonical}) => {
	useEffect(() => {
		document.title = title;
		setMetaTag('name', 'description', description);
		setMetaTag('name', 'keywords', Array.isArray(keywords) ? keywords.join(', ') : keywords);
		setCanonical(canonical);
	}, [title, description, keywords, canonical]);

	const setMetaTag = (attr, key, content) => {
	
		if (content) {
			let element = document.querySelector(`meta[${attr}="${key}"]`);
			if (!element) {
				element = document.createElement('meta');
				element.setAttribute(attr, key);
				document.head.appendChild(element);
			}
			element.setAttribute('content', content);
		}
	};

	const setCanonical = (href) => {
		let element = document.querySelector('link[rel="canonical"]');
		if (!element) {
			element = document.createElement('link');
			element.setAttribute('rel', 'canonical');
			document.head.appendChild(element);
		}
		if (href) {
			element.setAttribute('href', href);
		} else {
			document.head.removeChild(element);
		}
	};

	return null; // Bu bileşen sadece SEO ayarlarını yapmak için kullanılıyor, görünüm render etmiyor
};

export default DynamicSEO;

import { useEffect, useState } from 'react';
import './Agewarning.css';

const Agewarning = () => {
	const [isVisible, setIsVisible] = useState(false);
	const [isAdult, setIsAdult] = useState(false);

	useEffect(() => {
		const lastVisit = localStorage.getItem('lastVisit');
		const currentTime = new Date().getTime();
		const twentyFourHours = 24 * 60 * 60 * 1000;

		if (lastVisit && currentTime - Number(lastVisit) < twentyFourHours) {
			setIsAdult(true);
			setIsVisible(false);
		} else {
			setIsVisible(true);
		}
	}, []);

	const closeModal = () => {
		setIsVisible(false);
		localStorage.setItem('lastVisit', new Date().getTime());
	};

	const handleAgeVerification = (adult) => {
		setIsAdult(adult);
		setIsVisible(false);
		if (adult) {
			localStorage.setItem('lastVisit', new Date().getTime());
		}
	};

	const redirectUser = () => {
		document.querySelector('.modalMTubes .warningText').innerHTML =
			'Kullanım şartlarımız gereği 18 yaş altı bireylerin bu siteye erişimi uygun değildir. Google ana sayfasına yönlendirileceksiniz.';
		setTimeout(() => {
			window.location.href = 'https://www.google.com';
		}, 5000);
	};

	if (!isVisible) return null;

	return (
		<div className='modalMTubes'>
			<div className='contentMTubes'>
				<img
					className='logoImg'
					src='/images/warning.webp'
					alt=''
				/>
				<h3 className='heading1'>Bu Site Yetişkinler İçindir.</h3>

				<p className='warningText'>
					Bu web sitesi çıplaklık ve cinsel içerikli açık tasvirler de dahil olmak üzere yaş sınırlaması olan
					materyaller içermektedir. Giriş yaparak, en az 18 yaşında olduğunuzu veya web sitesine eriştiğiniz
					ve cinsel içerikli İçeriğin görüntülenmesine izin verdiğiniz yargı yetkisine sahip aşağıdaki
					ülkelerde reşit olduğunuzu onaylarsınız.
				</p>

				<button
					className='orangeButton'
					onClick={() => handleAgeVerification(true)}>
					Evet 18 yaşından büyüğüm - Giriş
				</button>
				<button
					rel='noopener nofollow'
					className='greyButton'
					onClick={redirectUser}>
					18 yaşın altındayım - Çıkış
				</button>
			</div>
		</div>
	);
};

export default Agewarning;

import React from 'react';
import './Iletisim.css';
import DynamicSEO from '../hooks/DynamicSEO';
import Telegram from './Telegram';

function Iletisim() {
  DynamicSEO({
    title: 'İletişim - NudeCenneti',
    description:
      'Sorularınız, önerileriniz veya destek ihtiyaçlarınız için bizimle iletişime geçin. Size en iyi hizmeti sunmak için buradayız!',
    keywords: 'İletişim, bize ulaşın, destek, müşteri hizmetleri, geri bildirim, iletişim bilgileri',
  });

  return (
    <div className="iletisim">
      <h1>İletişim</h1>
      <p>
        Soru, öneri veya destek talepleriniz için bizimle doğrudan iletişime geçin! Kullanıcılarımızın memnuniyeti bizim önceliğimizdir, ve her türlü geri bildiriminize açığız. Size en hızlı şekilde yardımcı olmaktan mutluluk duyacağız.
      </p>
      <p><strong>Email:</strong> <a href="mailto:isteknudecenneti@gmail.com">isteknudecenneti@gmail.com</a></p>
      <p>
        <strong>Destek Saatleri:</strong> Haftanın her günü, 09:00 - 18:00 arasında bize ulaşabilirsiniz.
      </p>
      <p>
        Sitemiz ve hizmetlerimizle ilgili daha fazla bilgi almak veya bize doğrudan ulaşmak için yukarıdaki iletişim bilgilerini kullanın!
      </p>
      <Telegram /> 
    </div>
  );
}

export default Iletisim;

import React from 'react';
import './GizlilikPolitikasi.css';
import DynamicSEO from '../hooks/DynamicSEO';
import Telegram from './Telegram';

function GizlilikPolitikasi() {

  DynamicSEO({
    title: 'Gizlilik Politikası - NudeCenneti',
    description:
      'Kişisel verilerinizin nasıl toplandığını, kullanıldığını ve korunduğunu açıklayan Gizlilik Politikamız. Güvenliğiniz önceliğimizdir.',
    keywords: 'Gizlilik Politikası, kişisel veriler, veri güvenliği, kullanıcı gizliliği, veri koruma',
  });

  return (
    <div className="gizlilik-politikasi">
      <h1>Gizlilik Politikası</h1>
      <p>Son Güncelleme: 28 Ekim 2024</p>

      <h2>1. Genel Bilgiler</h2>
      <p>
        NudeCenneti olarak, gizliliğinizi ve kişisel verilerinizi koruma konusunda yüksek standartlara sahibiz. Bu Gizlilik Politikası, kişisel verilerinizin nasıl işlendiğini ve korunduğunu detaylandırır.
      </p>

      <h2>2. Topladığımız Bilgiler</h2>
      <p>
        Hizmetlerimizi kullanırken aşağıdaki bilgileri toplayabiliriz:
        <ul>
          <li>Kimlik bilgileri (ad, e-posta adresi vb.)</li>
          <li>Kullanım bilgileri (ziyaret edilen sayfalar, tıklama bilgileri vb.)</li>
          <li>Teknik bilgiler (IP adresi, cihaz türü vb.)</li>
        </ul>
      </p>

      <h2>3. Bilgilerin Kullanımı</h2>
      <p>
        Topladığımız bilgileri şu amaçlarla kullanabiliriz:
        <ul>
          <li>Hizmetlerin sağlanması ve iyileştirilmesi</li>
          <li>Kişisel deneyim sunulması</li>
          <li>Yasal yükümlülüklerin yerine getirilmesi</li>
          <li>Promosyon ve pazarlama iletişimleri</li>
        </ul>
      </p>

      <h2>4. Bilgilerin Paylaşımı</h2>
      <p>
        Bilgileriniz yalnızca belirli durumlarda üçüncü taraflarla paylaşılır:
        <ul>
          <li>Yasal zorunluluklar</li>
          <li>Hizmet sağlayıcı iş ortakları</li>
          <li>Şirket devri durumları</li>
        </ul>
      </p>

      <h2>5. Güvenlik</h2>
      <p>
        Veri güvenliğinizi sağlamak için çeşitli teknik önlemler almaktayız. Ancak, internet üzerinden veri iletiminin %100 güvenli olduğunu garanti edemeyiz.
      </p>

      <h2>6. Kullanıcı Hakları</h2>
      <p>
        Kullanıcılar aşağıdaki haklara sahiptir:
        <ul>
          <li>Verilere erişim ve güncelleme</li>
          <li>Verilerin silinmesini talep etme</li>
          <li>Veri işlemeye itiraz etme</li>
        </ul>
      </p>

      <h2>7. Çocukların Gizliliği</h2>
      <p>
        Hizmetlerimiz 13 yaş altı kullanıcılar için tasarlanmamıştır. Bu yaş grubundan bilgi toplandığını fark edersek, verileri hemen sileceğiz.
      </p>

      <h2>8. Politika Güncellemeleri</h2>
      <p>
        Gizlilik Politikamızda yapılan değişiklikler bu sayfada duyurulacaktır. Güncellemelerden sonra siteyi kullanmaya devam etmeniz, yeni politikayı kabul ettiğiniz anlamına gelir.
      </p>

      <h2>9. İletişim</h2>
      <p>
        Sorularınız veya talepleriniz için bize <a href="mailto:isteknudecenneti@gmail.com">isteknudecenneti@gmail.com</a> adresinden ulaşabilirsiniz.
      </p>
      <Telegram /> 

    </div>
  );
}

export default GizlilikPolitikasi;

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import PremiumModal from './PremiumModal';
import { AuthContext } from '../context/authContext';
import './Video.css';
import './Pagination.css';
import { VideoSchema } from '../Schemas/VideoSchema';
import DynamicSEO from '../hooks/DynamicSEO';
import corpSchema from '../Schemas/corpSchema';

function Video() {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isAuthenticated, isPremium } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [videoBlobs, setVideoBlobs] = useState({});

  corpSchema();
  DynamicSEO({
    title: 'Nude Cenneti',
    description:
      'Yetişkinlere özel sitemizde, yüksek kaliteli cinsel içerikler, videolar ve görseller sunuyoruz. Güvenli, anonim ve hızlı erişim ile aradığınız her şeyi bulabilirsiniz.',
    keywords: 'cinsel içerik, yetişkin videoları, erotik görseller, yetişkinlere özel içerik, erotik videolar',
    canonical: `${window.location.href}`,
  });

  const fetchVideos = async () => {
    try {
      console.log('Fetching videos for page:', currentPage);
      const response = await axios.get(`https://benudecenneti.com/api/media/video?page=${currentPage}`);
      console.log('Fetched videos:', response.data);
      setVideos(response.data.videos);
      setTotalPages(response.data.totalPages);
      console.log('videos : ', videos);
    } catch (error) {
      console.error('Error fetching videos:', error);
      setError('Failed to fetch videos. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      setLoading(true);
    }
  };

  const handleVideoClick = (video) => {
    if (isAuthenticated && isPremium) {
      setModalContent(video);
    } else {
      setModalContent({ type: 'premium' });
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const renderVideos = () => {
    return videos.map((video) => {
      const shouldBlur = !isAuthenticated || !isPremium;
      const videoUrl = videoBlobs[video._id];

      return (
        <div
          key={video._id}
          className='video-item'
          onClick={() => handleVideoClick(video)}>
          <div className='video-container'>
            {videos && (
              <video
                src={`${video.filePath}#t=0.001`}
                className={shouldBlur ? 'blurred' : ''}
                controls
                preload='metadata'
                playsInline
                alt={video.filename.slice(0, -4)}>
                Your browser does not support the video tag.
              </video>
            )}
            {shouldBlur && (
              <div className='blur-overlay'>
                <span className='premium-badge'>Premium</span>
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  const renderPaginationButtons = () => {
    const pageNumbers = [];
    const ellipsis = <span className='pagination-ellipsis'>...</span>;

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pageNumbers.push(1, 2, 3, 4, ellipsis, totalPages);
      } else if (currentPage >= totalPages - 2) {
        pageNumbers.push(1, ellipsis, totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        pageNumbers.push(1, ellipsis, currentPage - 1, currentPage, currentPage + 1, ellipsis, totalPages);
      }
    }

    return pageNumbers.map((number, index) => (
      <React.Fragment key={index}>
        {number === ellipsis ? (
          ellipsis
        ) : (
          <button
            onClick={() => handlePageChange(number)}
            className={`pagination-button ${currentPage === number ? 'active' : ''}`}>
            {number}
          </button>
        )}
      </React.Fragment>
    ));
  };

  if (loading) return <div className='loading'>Loading...</div>;
  if (error) return <div className='error'>{error}</div>;

  return (
    <div className='video'>
      <h1>Video Galerisi</h1>

      <div className='video-grid'>{renderVideos()}</div>

      {isModalOpen && (
        <PremiumModal
          content={modalContent}
          onClose={closeModal}
          isAuthenticated={isAuthenticated}
          isPremium={isPremium}
        />
      )}

      <div className='pagination'>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className='pagination-button'>
          Previous
        </button>
        {renderPaginationButtons()}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className='pagination-button'>
          Next
        </button>
      </div>
      <VideoSchema videos={videos} />
    </div>
  );
}

export default Video;